<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <el-form-item label="学生ID：" label-width="75px" style="width: 320px;">
              <el-input placeholder="请输入学生ID" size="mini" class="form-inp" v-model="formLabelAlign.id"></el-input>
            </el-form-item>
            <el-form-item label="班级ID：" label-width="75px" style="width: 320px;">
              <el-input placeholder="请输入班级ID" size="mini" class="form-inp" v-model="formLabelAlign.class_id"></el-input>
            </el-form-item>
            <el-form-item label="学生姓名：" label-width="90px">
              <el-input placeholder="请输入学生姓名" size="mini" class="form-inp" v-model="formLabelAlign.truename"></el-input>
            </el-form-item>

          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个学生</span>
            <span class="span2">已选择 {{ more_List.length }} 个学生</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()">数据更新</i>
          </p>
          <div>
            <el-button type="primary" @click="on_skipaddplus()" icon="el-icon-plus">新建</el-button>
            <el-button type="danger" @click="on_deleteList" icon="el-icon-close">删除</el-button>
            <el-button @click="on_export">导出</el-button>
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="truename" label="姓名"></el-table-column>
            <el-table-column prop="class_name" label="班级"></el-table-column>
            <el-table-column prop="department" label="学院"></el-table-column>
            <el-table-column prop="stu_no" label="学号"></el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column prop="address" label="住址"></el-table-column>
            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary" @click="on_skipaddplus(scope.row.id)">修改</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import { studentindex, studentdelete } from "../../assets/request/api";
export default {
  name: "student_management",
  mixins: [table_minin],
  data() {
    return {
      formLabelAlign: {
        class_id: "",
        id: "",
        truename: "",
        status: "",
      },
    };
  },
  created() {
    this.url = studentindex;
    this.delete_Url = studentdelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "status_list";
    this.way_tableData();
  },
  methods: {
    on_skipaddplus(id) {
      this.$router.push({
        path: "/system/student_redacy",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
</style>
